import './SubjectRepositoryStatsBox.less'

import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { SubjectRepositoryStatsType } from '../../../../requests'
import { DatacBox, DatacIcon, DatacTitle } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

interface SubjectRepositoryStatsBoxProps {
  type: SubjectRepositoryStatsType
}

export const SubjectRepositoryStatsBox: React.VFC<SubjectRepositoryStatsBoxProps> = ({ type }) => {
  const intlStats = useScopedIntl('subject_repository.stats')
  const { stats } = useSubjectRepositoryDashboardStore()

  if (!stats?.[type]?.all) return null

  const getPercent = (value: number) => (stats[type].all ? `${Math.round((100 * value) / stats[type].all)}%` : '-')

  return (
    <DatacBox className="subject-repository-stats-box">
      <div className="subject-repository-stats-box__all">
        <DatacIcon name="users" raw />
        <DatacTitle>{stats[type].all || 0}</DatacTitle>
        <div className="subject-repository-stats-box__description">
          {intlStats(`description.${type.toLowerCase()}`)}
        </div>
      </div>
      <div className="subject-repository-stats-box__content">
        <div className="subject-repository-stats-box__content__indicator subject-repository-stats-box__content__indicator--active" />
        <div>
          <span className="subject-repository-stats-box__number">{stats[type].active || 0}</span>
          {getPercent(stats[type].active)}
          <div className="subject-repository-stats-box__description">{intlStats('active')}</div>
        </div>
      </div>
      <div className="subject-repository-stats-box__content">
        <div className="subject-repository-stats-box__content__indicator" />
        <div>
          <span className="subject-repository-stats-box__number">{stats[type].inactive || 0}</span>
          {getPercent(stats[type].inactive)}
          <div className="subject-repository-stats-box__description">{intlStats('inactive')}</div>
        </div>
      </div>
    </DatacBox>
  )
}
