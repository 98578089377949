import { RouteComponentProps, useMatch } from '@reach/router'
import React from 'react'

import { useScopedIntl } from '../../../hooks'
import { routes } from '../../../routes'
import { BuilderElements } from '../../shared/Builder'
import { SubjectRepositoryBuilder } from './SubjectRepositoryBuilder'

export const SubjectRepositoryBuilderContent: React.FC<RouteComponentProps> = ({ location }) => {
  const match = useMatch('section/:sectionId')
  const newStructure = !match
  const intlSubjectRepository = useScopedIntl('subject_repository')

  const showInBuilder = () => {
    if (newStructure) return BuilderElements.BuilderEntry

    return BuilderElements.Structure
  }

  return (
    <SubjectRepositoryBuilder
      studyId={null}
      sectionId={match?.sectionId}
      location={location}
      backRoute={routes.subjectRepositorySettings}
      title={intlSubjectRepository('builder.title')}
      showInBuilder={showInBuilder()}
    />
  )
}
