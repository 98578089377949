import { Form, Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { ExportFileFormat, exportSubjectRepository } from '../../../../requests'
import { DatacFormItem, DatacMessage, DatacModal, DatacSelect } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

export const ExportModal: React.VFC = () => {
  const intlModal = useScopedIntl('subject_repository.export.modal')
  const intl = useScopedIntl('')
  const [isExporting, setIsExporting] = useState(false)
  const [formInstance] = Form.useForm()
  const cancelExportRef = useRef<() => void>(() => null)
  const [exportFiles, setExportFiles] = useState(false)
  const {
    selectedSubjects,
    search,
    conditions,
    status,
    isExportModalOpened,
    setIsExportModalOpened,
    isEverythingSelected
  } = useSubjectRepositoryDashboardStore()

  useEffect(() => {
    if (isExportModalOpened) {
      formInstance.resetFields()
      setExportFiles(false)
    } else {
      cancelExportRef.current()
      setIsExporting(false)
    }
  }, [isExportModalOpened])

  const onExport = ({ exportFileFormat }: { exportFileFormat: ExportFileFormat }) => {
    setIsExporting(true)
    const cancel = exportSubjectRepository(
      {
        exportFileFormat,
        exportFiles,
        search,
        conditions,
        subjects: isEverythingSelected ? undefined : selectedSubjects,
        status
      },
      {
        onSuccess: () => {
          setIsExporting(false)
          setIsExportModalOpened(false)
        },
        onRequestError: code => {
          setIsExporting(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )

    cancelExportRef.current = cancel
  }

  const exportFileFormatOptions = [
    { label: intl('common.file_format.csv'), value: ExportFileFormat.Csv },
    { label: intl('common.file_format.excel'), value: ExportFileFormat.Excel }
  ]

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isExportModalOpened}
      onClose={() => setIsExportModalOpened(false)}
      onSubmit={() => formInstance.submit()}
      submitLabel={intlModal('submit')}
      loading={isExporting}
    >
      <Form form={formInstance} onFinish={onExport} name="subject-repository-export-modal-form">
        <DatacFormItem
          name="exportFileFormat"
          label={intlModal('export_file_format.label')}
          initialValue={ExportFileFormat.Csv}
        >
          <DatacSelect options={exportFileFormatOptions} />
        </DatacFormItem>
        <DatacFormItem label={intlModal('export_files.label')}>
          <Switch checked={exportFiles} onChange={() => setExportFiles(!exportFiles)} />
        </DatacFormItem>
      </Form>
    </DatacModal>
  )
}
