import './SubjectRepositoryFilters.less'

import { Button, Form } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  Condition,
  ConditionalLogicOperator,
  Structure,
  SubjectStatus,
  fetchSubjectRepositoryQuestionsDependencies
} from '../../../../requests'
import { getConditionalQuestionById } from '../../../../utils'
import { DatacIcon, DatacSelect, DatacSingleCondition, DatacToggleExpand } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

const maxConditions = 30

export const SubjectRepositoryFilters: React.VFC = () => {
  const [formInstance] = Form.useForm()
  const intlCommon = useScopedIntl('common')
  const intlFilters = useScopedIntl('subject_repository.filters')
  const intlStatus = useScopedIntl('status')
  const [conditionalQuestions, setConditionalQuestions] = useState<Structure>(null)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { conditions, setConditions, status, setStatus } = useSubjectRepositoryDashboardStore()

  useEffect(() => {
    fetchSubjectRepositoryQuestionsDependencies(
      { studyId: null },
      {
        onSuccess: ({ conditionalQuestions }) => {
          setConditionalQuestions(conditionalQuestions)
        }
      }
    )
  }, [])

  useEffect(() => {
    formInstance.setFieldsValue({ status, conditions })
  }, [])

  const onClear = () => {
    formInstance.resetFields()
    setConditions([])
    setStatus(null)
  }

  const onValuesChange = (conditions: Condition[]) => {
    formInstance.validateFields()
    const existingConditions = conditions.filter(c => c.referenceQuestionId || c.answerValue != null || c.operator)
    const isValid = !existingConditions.some(
      c =>
        !c.referenceQuestionId ||
        c.answerValue == null ||
        !c.operator ||
        (Array.isArray(c.answerValue) && !c.answerValue.length)
    )
    if (isValid) setConditions(existingConditions)
  }

  const statusOptions = [
    { value: null, label: intlFilters('all_statuses') },
    { value: SubjectStatus.New, label: intlStatus('new') },
    { value: SubjectStatus.Active, label: intlStatus('active') },
    { value: SubjectStatus.Inactive, label: intlStatus('inactive') },
    { value: SubjectStatus.Pending, label: intlStatus('pending') },
    { value: SubjectStatus.Paused, label: intlStatus('paused') },
    { value: SubjectStatus.DeletionRequested, label: intlStatus('deletion_requested') }
  ]

  return (
    <div className="subject-repository-filters">
      <div className="subject-repository-filters__header">
        <DatacIcon name="filter" raw />
        <div className="subject-repository-filters__header__title">{intlFilters('title')}</div>
        {(status || !!conditions.length) && (
          <div className="subject-repository-filters__header__clear" onClick={onClear}>
            {intlCommon('clear')}
          </div>
        )}
        <DatacToggleExpand isCollapsed={isCollapsed} onToggle={setIsCollapsed} />
      </div>
      {!isCollapsed && (
        <div className="subject-repository-filters__content">
          <div className="subject-repository-filters__description">{intlFilters('description')}</div>
          <Form
            form={formInstance}
            name="subject-repository-filters"
            onValuesChange={(_, values) => onValuesChange(values?.conditions || [])}
          >
            <Form.Item name="status" className="subject-repository-filters__status">
              <DatacSelect
                className="subject-repository-filters__status__condition subject-repository-filters__condition"
                options={statusOptions}
                onSelect={setStatus}
                placeholder={intlFilters('all_statuses')}
              />
            </Form.Item>
            <Form.List name="conditions">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <div className="subject-repository-filters__conditions">
                      {fields.map((field, index) => {
                        const setConditionFormValue = (
                          getFieldValue: FormInstance['getFieldValue'],
                          setFieldsValue: FormInstance['setFieldsValue'],
                          fieldName: string,
                          value: string
                        ) => {
                          const allConditions = getFieldValue('conditions') || []

                          const newConditions = allConditions?.map((condition: Condition, key: number) => ({
                            ...condition,
                            ...(key === field.key ? { [fieldName]: value } : undefined)
                          }))
                          setFieldsValue({ conditions: newConditions })
                        }

                        return (
                          <div key={index} className="subject-repository-filters__condition">
                            <DatacSingleCondition
                              field={field}
                              fieldPath={['conditions', field.name]}
                              getConditionalQuestionById={(questionId: string) =>
                                getConditionalQuestionById(conditionalQuestions, questionId)
                              }
                              conditionalQuestions={conditionalQuestions}
                              availableBlocks={conditionalQuestions?.sections}
                              setConditionFormValue={setConditionFormValue}
                              sectionsHidden
                            />
                            <Button
                              className="datac-button--icon-only subject-repository-filters__condition__delete"
                              onClick={() => remove(field.name)}
                            >
                              <DatacIcon name="trash" type="red" />
                            </Button>
                          </div>
                        )
                      })}
                    </div>
                    {fields?.length < maxConditions && (
                      <div
                        className="subject-repository-filters__new-condition"
                        onClick={() => add({ operator: ConditionalLogicOperator.Equal })}
                      >
                        <DatacIcon name="plus" raw /> {intlFilters('new_condition')}
                      </div>
                    )}
                  </>
                )
              }}
            </Form.List>
          </Form>
        </div>
      )}
    </div>
  )
}
