import './ResendModal.less'

import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { resendQuestionnaires } from '../../../../requests'
import { DatacInformationMessage, DatacMessage, DatacModal } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

interface Props {
  subjectsWithEmailCount: number
}

export const ResendModal: React.VFC<Props> = ({ subjectsWithEmailCount }) => {
  const intlModal = useScopedIntl('subject_repository.resend.modal')
  const intl = useScopedIntl('')

  const {
    selectedSubjects,
    search,
    conditions,
    status,
    isResendModalOpened,
    setIsResendModalOpened,
    isEverythingSelected
  } = useSubjectRepositoryDashboardStore()

  const onSendMessage = () => {
    resendQuestionnaires(
      {
        search,
        conditions,
        status,
        subjects: isEverythingSelected ? undefined : selectedSubjects
      },
      {
        onSuccess: () => setIsResendModalOpened(false),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isResendModalOpened}
      onClose={() => setIsResendModalOpened(false)}
      onSubmit={() => onSendMessage()}
      destroyOnClose
    >
      <div className="subject-repository-resend-modal__body">
        <DatacInformationMessage message={intlModal('subject_count_info', { count: subjectsWithEmailCount })} />
      </div>
    </DatacModal>
  )
}
