import './AssignToRecruitmentModal.less'

import { Form, Switch } from 'antd'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  CandidateStatus,
  RecruitmentStudy,
  RecruitmentStudyStatus,
  SorterOrder,
  assignToRecruitment,
  fetchRecruitmentStudies
} from '../../../../requests'
import { enumToOptions } from '../../../../utils'
import { validateRequired } from '../../../../validation'
import {
  DatacFormItem,
  DatacInformationMessage,
  DatacLoading,
  DatacMessage,
  DatacModal,
  DatacOption,
  DatacSelect
} from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

const studiesListLimit = 50
const allowedStatuses = [RecruitmentStudyStatus.Recruiting]

interface FormData {
  recruitmentId: string
  centerId: string
  candidateStatus: CandidateStatus
}

interface Props {
  subjectsCount: number
}

export const AssignToRecruitmentModal: React.VFC<Props> = ({ subjectsCount }) => {
  const intlModal = useScopedIntl('subject_repository.assign.modal')
  const intlStatus = useScopedIntl('status')
  const intl = useScopedIntl('')
  const [studies, setStudies] = useState<RecruitmentStudy[]>([])
  const [isFetchingStudyList, setIsFetchingStudyList] = useState(true)
  const [studiesOptions, setStudiesOptions] = useState<DatacOption[]>([])
  const [isSendingInvitations, setIsSendingInvitations] = useState(true)
  const [currentStudy, setCurrentStudy] = useState<RecruitmentStudy>(null)
  const [form] = Form.useForm()
  const {
    selectedSubjects,
    search,
    conditions,
    status,
    isAssignToRecruitmentModalOpened,
    setIsAssignToRecruitmentModalOpened,
    centersOptions,
    isEverythingSelected
  } = useSubjectRepositoryDashboardStore()

  const onModalClose = () => {
    form.resetFields()
    setIsSendingInvitations(true)
    setCurrentStudy(null)
    setIsAssignToRecruitmentModalOpened(false)
  }

  const onSubmit = ({ recruitmentId, centerId, candidateStatus }: FormData) => {
    assignToRecruitment(
      {
        search,
        conditions,
        status,
        subjects: isEverythingSelected ? undefined : selectedSubjects,
        recruitmentId,
        centerId,
        candidateStatus,
        isSendingInvitations
      },
      {
        onSuccess: () => setIsAssignToRecruitmentModalOpened(false),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  useEffect(() => {
    fetchStudiesList()
  }, [])

  useEffect(() => {
    setStudiesOptions(studies.map(study => ({ label: study.reference, sublabel: study.name, value: study.id })))
  }, [studies])

  const fetchStudiesList = (searchPhrase?: string) => {
    fetchRecruitmentStudies(
      {
        options: {
          sorter: { order: SorterOrder.Descend, field: 'name' },
          limit: studiesListLimit,
          offset: 0,
          filters: { status: allowedStatuses },
          search: searchPhrase
        }
      },
      {
        onSuccess: newStudies => {
          setStudies(newStudies)
          setIsFetchingStudyList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingStudyList(false)
        }
      }
    )
  }

  const onSearch = debounce((newPhrase: string) => {
    fetchStudiesList(newPhrase)
  }, 1000)

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isAssignToRecruitmentModalOpened}
      onClose={onModalClose}
      onSubmit={() => form.submit()}
      destroyOnClose
      className="assign-to-recruitment-modal"
    >
      <div className="assign-to-recruitment-modal__body">
        <DatacInformationMessage
          message={intlModal('subject_count_info', {
            count: isEverythingSelected ? subjectsCount : selectedSubjects?.length
          })}
        />
        <Form form={form} onFinish={onSubmit}>
          <DatacLoading isLoading={isFetchingStudyList}>
            <DatacFormItem
              name="recruitmentId"
              label={intlModal('study.label')}
              validate={validateRequired(intl('common.required'))}
            >
              <DatacSelect
                options={studiesOptions}
                size="large"
                placeholder={intlModal('study.placeholder')}
                onSearch={onSearch}
                showSearch
                loading={isFetchingStudyList}
                onChange={s => setCurrentStudy(studies.find(study => study.id === s))}
              />
            </DatacFormItem>
            {!!currentStudy && (
              <DatacFormItem
                name="centerId"
                label={intlModal('center.label')}
                validate={validateRequired(intl('common.required'))}
              >
                <DatacSelect
                  showSearch
                  size="large"
                  options={centersOptions.filter((c: DatacOption) => currentStudy?.centerIds.includes(c.value))}
                  placeholder={intlModal('center.placeholder')}
                />
              </DatacFormItem>
            )}
            <DatacFormItem
              name="candidateStatus"
              label={intlModal('status.label')}
              validate={validateRequired(intl('common.required'))}
              initialValue={CandidateStatus.New}
            >
              <DatacSelect options={enumToOptions(CandidateStatus, intlStatus)} />
            </DatacFormItem>

            <DatacFormItem label={intlModal('send_invitations.label')}>
              <Switch checked={isSendingInvitations} onChange={setIsSendingInvitations} />
            </DatacFormItem>
          </DatacLoading>
        </Form>
      </div>
    </DatacModal>
  )
}
