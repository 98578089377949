import React, { useContext } from 'react'
import Flag from 'react-world-flags'

import {
  AclAction,
  AclFeature,
  InternationalPhoneNumber,
  SimpleAnswer,
  SubjectInRepository,
  SubjectStatus,
  downloadSubjectRepositoryFileAnswer
} from '../../../requests'
import { UserContext } from '../../auth'
import {
  ColumnFixedType,
  DatacColorStatusSelect,
  DatacDropdownMenu,
  DatacDropdownMenuOptions,
  DatacFileDownloadPreview,
  DatacTooltip
} from '../../common'

enum SubjectRepositoryActionType {
  Delete = 'delete'
}

export const ActionCell: React.VFC<{
  onDelete: () => void
}> = ({ onDelete }) => {
  const { user } = useContext(UserContext)
  const userCan = user.canDo(AclFeature.SubjectRepository)

  const onClick = (item: string) => {
    switch (item) {
      case SubjectRepositoryActionType.Delete:
        onDelete()
        break
      default:
        break
    }
  }

  const menuOptions: DatacDropdownMenuOptions<SubjectRepositoryActionType> = [
    userCan(AclAction.Add) && {
      type: SubjectRepositoryActionType.Delete,
      label: 'common.delete',
      icon: 'trash',
      customClass: 'dropdown-menu__item--red',
      confirmAction: true
    }
  ]

  return (
    <div className="subject-repository-dashboard__table__row-actions">
      <DatacDropdownMenu options={menuOptions} onClick={item => onClick(item)} />
    </div>
  )
}

const FullNameCell: React.VFC<{ subject: SubjectInRepository }> = ({ subject }) => {
  return (
    <div className="subject-repository-dashboard__table__name">
      <div className="subject-repository-dashboard__table__name__full">
        {subject.firstName} {subject.lastName}
      </div>
      <div className="subject-repository-dashboard__table__name__phone">{subject.email}</div>
    </div>
  )
}

const LanguageCell: React.VFC<{ language: string }> = ({ language }) => {
  return language && <div className="subject-repository-dashboard__table__language">{language?.toUpperCase()}</div>
}

const PhoneCell: React.VFC<{ internationalPhoneNumber: InternationalPhoneNumber }> = ({ internationalPhoneNumber }) => {
  return (
    internationalPhoneNumber && (
      <div className="subject-repository-dashboard__table__phone">
        <Flag code={internationalPhoneNumber.short} /> +{internationalPhoneNumber.code} {internationalPhoneNumber.phone}
      </div>
    )
  )
}

const StatusCell: React.VFC<{ status: SubjectStatus }> = ({ status }) => {
  return status && <DatacColorStatusSelect<SubjectStatus> status={status} forceRedStatuses={[SubjectStatus.New]} />
}

const CustomCell: React.VFC<{ answer: SimpleAnswer }> = ({ answer }) => {
  if (answer.isFile)
    return (
      <DatacFileDownloadPreview
        filename={answer.name}
        fileId={answer.uuid}
        downloadFile={downloadSubjectRepositoryFileAnswer}
      />
    )

  return (
    answer.isFile === false && (
      <DatacTooltip title={answer.value}>
        <div className="subject-repository-dashboard__table__custom">{answer.value}</div>
      </DatacTooltip>
    )
  )
}

interface GetColumnsOptions {
  columnNames: {
    id: string
    fullName: string
    phone: string
    language: string
    status: string
    actions: string
  }
  sortable: boolean
  variables: Record<string, string>
}

export const getColumns = ({ columnNames, sortable, variables }: GetColumnsOptions) => {
  const variablesArray = Object.entries(variables)
  return [
    {
      title: columnNames.id,
      dataIndex: 'id',
      key: 'id',
      width: '11rem',
      ellipsis: true,
      fixed: 'left' as ColumnFixedType,
      sorter: sortable
    },
    {
      title: columnNames.fullName,
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      width: variablesArray.length > 4 && '24rem',
      fixed: 'left' as ColumnFixedType,
      sorter: sortable,
      render: (_: string, subject: SubjectInRepository) => <FullNameCell subject={subject} />
    },
    {
      title: columnNames.phone,
      dataIndex: 'internationalPhoneNumber',
      key: 'phone',
      width: '17rem',
      ellipsis: true,
      sorter: sortable,
      render: (internationalPhoneNumber: InternationalPhoneNumber) => (
        <PhoneCell internationalPhoneNumber={internationalPhoneNumber} />
      )
    },
    {
      title: columnNames.language,
      dataIndex: 'language',
      key: 'language',
      width: '10rem',
      sorter: sortable,
      render: (language: string) => <LanguageCell language={language} />
    },
    {
      title: columnNames.status,
      dataIndex: 'status',
      key: 'status',
      width: '22rem',
      sorter: sortable,
      render: (status: SubjectStatus) => <StatusCell status={status} />
    },
    ...variablesArray?.map(([variable, title]) => ({
      title: <DatacTooltip title={title}>{variable}</DatacTooltip>,
      dataIndex: variable,
      key: variable,
      width: '16rem',
      render: (answer: SimpleAnswer) => <CustomCell answer={answer} />
    }))
  ]
}
