import './SubjectRepositorySettings.less'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclAction, AclFeature } from '../../../requests'
import { routes } from '../../../routes'
import { UserContext } from '../../auth'
import { DatacIcon, DatacTitle } from '../../common'

const SubjectRepositorySettingsBox: React.FC<{ title: string }> = ({ children, title }) => (
  <div className="subject-repository-settings__box">
    <DatacTitle size="small">{title}</DatacTitle>
    <div className="subject-repository-settings__box__body">{children}</div>
  </div>
)

export const SubjectRepositorySettings: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const intlSettings = useScopedIntl('subject_repository.settings')
  const { user } = useContext(UserContext)
  const userCan = user.canDo(AclFeature.SubjectRepository)

  return (
    <div className="subject-repository-settings">
      <div className="subject-repository-settings__header">
        <div
          onClick={() => navigate(routes.subjectRepository)}
          className="subject-repository-settings__header__go-back"
        >
          <DatacIcon
            className="subject-repository-settings__header__go-back-icon"
            name="arrowLeft"
            type="blue"
            size="small"
          />
          {intl('common.back')}
        </div>
        <div>
          <DatacTitle type="h1">{intlSettings('title')}</DatacTitle>
        </div>
        <div className="subject-repository-settings__header__description">{intlSettings('description')}</div>
      </div>
      {user.canAccess(AclFeature.SubjectRepositoryBuilder) && (
        <SubjectRepositorySettingsBox title={intlSettings('builder')}>
          <div
            className="subject-repository-settings__row  subject-repository-settings__row--clickable"
            onClick={() => navigate(routes.subjectRepositoryBuilder)}
          >
            <div>
              <DatacTitle size="small">{intlSettings('builder_title')}</DatacTitle>
              <div className="subject-repository-settings__row__description">{intlSettings('builder_description')}</div>
            </div>
            <div className="subject-repository-settings__row__arrow">{'>'}</div>
          </div>
        </SubjectRepositorySettingsBox>
      )}
      {userCan(AclAction.BasicSettings) && (
        <SubjectRepositorySettingsBox title={intlSettings('qr_codes')}>
          <div
            className="subject-repository-settings__row  subject-repository-settings__row--clickable"
            onClick={() => navigate(routes.subjectRepositoryQrCodes)}
          >
            <div>
              <DatacTitle size="small">{intlSettings('qr_codes_title')}</DatacTitle>
              <div className="subject-repository-settings__row__description">
                {intlSettings('qr_codes_description')}
              </div>
            </div>
            <div className="subject-repository-settings__row__arrow">{'>'}</div>
          </div>
        </SubjectRepositorySettingsBox>
      )}
    </div>
  )
}
